<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="top">
      <v-flex xs12 align-self-start v-if="top.image">
        <v-img
          cover
          height="450px"
          class="align-end"
          :src="mediaURL + top.image"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Offline Programs</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-start wrap pt-4>
          <v-flex xs12 sm12 md7 align-self-start text-justify text-left pr-md-4>
            <span
              style="font-family: TitLight; font-size: 25px; color: black"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '14px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '20px'
                    : '35px',
              }"
            >
              World Wetlands Day 2022 celebration Speleological Association of
              India, along with Sálim Ali Centre for Ornithology and Natural
              History (SACON), Coimbatore (Tamil Nadu), Mangrove Foundation
              (Mangrove Cell, Maharashtra Forest Department), Indian Women
              Scientist Association (IWSA) Kolhapur branch, Maharashtra), Br.
              Khardekar College, Vengurla (Sindhudurg, Maharashtra) and Rotaract
              club of Vengurla (Midtown) celebrated the World Wetlands Day 2022.
              We conducted a special lecture on the topic, ‘Subterranean
              Wetlands- the hidden heritage of India” presented by Ms. Dhanusha
              Kawalkar (Director, SAI).
            </span>
          </v-flex>
          <v-flex xs12 md5 py-2 py-md-0 align-self-start v-if="evimg">
            <viewer :images="imgs" :key="imgs.length" style="display: flex">
              <v-layout wrap>
                <v-flex xs12 sm6 pa-2 v-for="(item, i) in imgs" :key="i">
                  <!-- <v-img :src="item.file"></v-img> -->
                  <img
                    style="object-fit: contain;cursor:pointer"
                    width="100%"
                    :src="item.file"
                  />
                </v-flex>
              </v-layout>
            </viewer>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      membership: [],
      top: [],
      evimg: require("./../../assets/images/one.jpg"),
      imgs: [
        {
          file: require("./../../assets/images/one.jpg"),
        },
        {
          file: require("./../../assets/images/two.jpg"),
        },
        {
          file: require("./../../assets/images/three.jpg"),
        },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/membership/page/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.membership = response.data.data;
            this.top = this.membership.top;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>
