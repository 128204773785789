<template>
  <div id="appNavbar">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
      <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in appNavItems"
          :key="i"
          text-center
          pa-2
          pl-0
          text-none
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <!-- <router-link :to="item.route"> -->
              <v-icon>{{ item.icon }}</v-icon>
              <span
                v-if="item.name == 'Research'"
                :style="
                  $route.path == item.route
                    ? {
                        'font-family': 'TitBold',
                      }
                    : {
                        'font-family': 'TitLight',
                      }
                "
                style="
                  font-size: 15px;
                  color: black;
                  letter-spacing: 3px;
                  cursor: pointer;
                "
              >
                <!-- {{ item.name }} -->
                <!-- <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-layout
                      justify-start
                      color="#86ab5ef3"
                      v-bind="attrs"
                      v-on="on"
                    > -->
                      <span
                        :style="
                          $route.path == item.route
                            ? {
                                'font-family': 'TitBold',
                              }
                            : {
                                'font-family': 'TitLight',
                              }
                        "
                        style="
                          font-size: 15px;
                          color: black;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        {{ item.name }}
                      </span>
                    <!-- </v-layout>
                  </template> -->
                  <v-list>
                    <v-list-item v-for="(item, index) in dropdown" :key="index">
                      <v-list-item-title>
                        <v-card width="200px">
                          <span
                            :style="
                              $route.path == item.route
                                ? {
                                    'font-family': 'TitBold',
                                  }
                                : {
                                    'font-family': 'TitLight',
                                  }
                            "
                            style="
                              font-size: 15px;
                              color: black;
                              letter-spacing: 3px;
                              cursor: pointer;
                            "
                            >{{ item.title }}</span
                          >
                        </v-card>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                <!-- </v-menu> -->
              </span>
              <span v-else-if="item.name == 'Get Involved'">
                <!-- <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-layout
                      justify-start
                      color="#86ab5ef3"
                      v-bind="attrs"
                      v-on="on"
                    > -->
                      <span
                        :style="
                          $route.path == item.route
                            ? {
                                'font-family': 'TitBold',
                              }
                            : {
                                'font-family': 'TitLight',
                              }
                        "
                        style="
                          font-size: 15px;
                          color: black;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        {{ item.name }}
                      </span>
                    <!-- </v-layout>
                  </template> -->
                  <v-list>
                    <v-list-item v-for="(item, index) in drop" :key="index">
                      <v-list-item-title>
                        <v-card width="200px">
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'TitBold',
                                    }
                                  : {
                                      'font-family': 'TitLight',
                                    }
                              "
                              style="
                                font-size: 15px;
                                color: black;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                              >{{ item.title }}</span
                            >
                          </router-link>
                        </v-card>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                <!-- </v-menu> -->
              </span>

               <span v-else-if="item.name == 'Activities'">
               
                      <span
                        :style="
                          $route.path == item.route
                            ? {
                                'font-family': 'TitBold',
                              }
                            : {
                                'font-family': 'TitLight',
                              }
                        "
                        style="
                          font-size: 15px;
                          color: black;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        {{ item.name }}
                      </span>
               
                  <v-list>
                    <v-list-item v-for="(item, index) in activities" :key="index">
                      <v-list-item-title>
                        <v-card width="200px">
                          <router-link :to="item.route">
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'TitBold',
                                    }
                                  : {
                                      'font-family': 'TitLight',
                                    }
                              "
                              style="
                                font-size: 15px;
                                color: black;
                                letter-spacing: 3px;
                                cursor: pointer;
                              "
                              >{{ item.title }}</span
                            >
                          </router-link>
                        </v-card>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                <!-- </v-menu> -->
              </span>

             
              <a v-else :href="item.route">
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'TitBold',
                        }
                      : {
                          'font-family': 'TitLight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </a>
              <!-- </router-link> -->
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#FFFFFF"
      dense
      flat
      height="50px"
      class="hidden-md-and-up"
    >
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="#000">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-layout wrap justify-center>
            <v-flex xs9 align-self-center>
              <span
                class="hidden-sm-and-up"
                style="
                  font-family: TitSemibold;
                  font-size: 16px;
                  cursor: pointer;
                  color: #000;
                "
              >
                SAI
              </span>
              <span
                style="
                  font-family: TitSemibold;
                  font-size: 16px;
                  cursor: pointer;
                  color: #000;
                "
                class="hidden-xs-only"
              >
                Speleological Association of India
              </span>
            </v-flex>

            <v-flex xs3 pa-0 sm3 md3 lg2 xl1 align-self-center text-left>
              <router-link class="routerlink" :to="(name = '/')">
                <v-img
                  contain
                  height="50px"
                  :src="require('./../assets/images/SAIcopy.png')"
                ></v-img>
              </router-link>
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <!-- <v-app-bar
      color="#FEBD08"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap justify-center fill-height>
        <v-flex xs2 align-self-center>
          <router-link to="/">
            <span
              style="
                font-family: TitBold;
                font-size: 20px;
                cursor: pointer;
                color: #FFFFFF;
              "
            >
              TIGERTHON
            </span>
          </router-link>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-app-bar> -->
  </div>
</template>
<script>
// import AccountMenu from "./../components/accountMenu";
export default {
  components: {
    // AccountMenu,
  },
  data() {
    return {
      sideNav: false,
      // navItems: [

      //   { name: "Rules", route: "/" },
      //   { name: "Resources", route: "/" },
      //   { name: "Project", route: "/" },
      //   { name: "Updates", route: "/" },
      //   { name: "Discussion", route: "/" },
      //   { name: "FAQ", route: "/" },
      // ],
      navItems: [
        { name: "About Us", route: "/About" },
        // { name: "Research", route: "" },
        { name: "Activities", route: "" },
        { name: "Get Involved", route: "" },
        { name: "Team", route: "/team" },
        { name: "Donate Us", route: "/DonateUs" },
        { name: "Contact Us", route: "/Contact" },
      ],
      activities:[
        // {
        //   title: "Upcoming Events",
        //   route:"/Events"
        // },
        
        {
          title: "Webinars",
          route: "/WebinarsHome",
        },
        {
          title: "Offline Programs",
          route:"/OfflinePrograms"
        },
        {
          title: "Cave Animal of the year Campaign",
          route:"/cave-animal-of-the-year-campaign"
        },
      ],
      dropdown: [
        {
          title: "Publications",
        },
        {
          title: "Projects",
        },
      ],
      drop: [
        {
          title: "Volunteer",
          route: "/Volunteer",
        },
        // {
        //   title: "Donate",
        //   route: "/About",
        // },
        {
          title: "Membership",
          route: "/membership",
        },
      ],
    };
  },
  computed: {
    appNavItems() {
      return this.navItems;
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>

