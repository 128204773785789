<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="top">
      <v-flex xs12 align-self-start v-if="top.image">
        <v-img
          cover
          height="450px"
          class="align-end"
          :src="mediaURL + top.image"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Upcoming Events</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap pt-4>
          <v-flex xs12 sm12 align-self-start text-justify text-left pr-md-4>
            <span
              style="font-family: TitLight; font-size: 25px; color: black"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '14px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '20px'
                    : '35px',
              }"
            >
              An aquifer is a body of rock capable of containing and
              transmitting large quantities of water. Most caves are created at
              or just below the water table in the zone of saturation. Caves are
              the largest pathway within the aquifer. This unique connection is
              responsible for the region's hydrology and provides suitable
              habitats for the highly specialized stygofauna. As part of
              celebrating the International year of caves and karst (IYCK),
              let's take a tour of the unique ecosystems of the planet Earth on
              21st June 2022, at 6 PM (IST) through a webinar on
              <b>"Aquifers and caves: the groundwater perspective" </b> by Mr
              Divyanshu Pawar. Divyanshu comes from an educational background of
              a bachelor's in Geology and a Masters in Ecology, Society, and
              Sustainable Development and is interested in Transdisciplinary
              studies. He has been working for 4 + years in the industry with
              NGOs, Think tanks, Academic institutions, and Independent
              consultants. His understanding of the field and transdisciplinary
              studies grew as he worked with multiple Environmental and Social
              NGOs with people from diverse backgrounds from an early age. He
              has extensively worked on Urban springs throughout these years,
              and also his master's thesis was on Geology, Ecology, and Social
              Understanding of these springs in an urban setting. An aspiring
              Ecohydrologist, he is currently working with the Bombay
              environmental Action group on groundwater aspects of laterite
              plateaus.

              <br />
              <br />
              <b>Date and Time: 21st June 2022, 6 PM IST</b>

              <br />
              <b>Registration Link: </b>
              <a
                style="color:blue !important"
                target="_blank"
                href="https://forms.gle/GdHbLwgN5yhcaesr8"
                >https://forms.gle/GdHbLwgN5yhcaesr8</a
              >
         

              <br />
              <b>Webinar Link: </b>
              <a
                style="color:blue !important"
                target="_blank"
                href="http://meet.google.com/dtk-xiak-gvy"
                >http://meet.google.com/dtk-xiak-gvy</a
              >
              <br />
              <br />

              <b>For any details, write to us at </b>
              <a
                style="color:blue !important"
                href="mailto:speleoindia@gmail.com"
                >speleoindia@gmail.com
              </a>

              <br />

              <br />
              <b> Note:</b> For those interested in receiving participation
              certificates, registration for the webinar is necessary.
              <br />

              <br />

              <b>Speleological Association of India</b>
              <br />
              Website:
              <a
                style="color:blue !important"
                href="https://speleoindia.org.in/"
                >www.speleoindia.org</a
              >

              <br />
              Instagram:
              <a
                style="color:blue !important"
                target="_blank"
                href="https://instagram.com/speleoindia?utm_medium=copy_link"
                >https://instagram.com/speleoindia?utm_medium=copy_link</a
              >

              <br />
              Facebook:
              <a
                style="color:blue !important"
                target="_blank"
                href="https://www.facebook.com/speleoindia/"
                >https://www.facebook.com/speleoindia/</a
              >
            </span>
          </v-flex>
          <v-flex xs12 md4 lg4 py-2 pa-2 pt-4 align-self-start v-if="evimg">
            <v-img :src="evimg"></v-img>
          </v-flex>
          
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      membership: [],
      top: [],
      evimg: require("./../../assets/images/eJune.jpeg"), 
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/membership/page/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.membership = response.data.data;
            this.top = this.membership.top;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>
