var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppHeader'),_c('v-divider'),(_vm.$vuetify.breakpoint.name !== 'xs')?_c('v-app-bar',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"hidden-sm-and-down headGrad",attrs:{"color":_vm.transAppBar && _vm.$route.path == '/' ? 'white' : 'white',"height":'75px',"elevation":"6","app":"","dark":"","elevate-on-scroll":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"align-self-center":"","xs12":"","px-md-2":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"align-self-center":"","sm6":"","md6":"","lg6":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs2":"","sm3":"","md2":"","lg2":"","xl1":"","py-0":"","align-self-start":"","text-left":""}},[_c('router-link',{staticClass:"routerlink",attrs:{"to":(_vm.name = '/')}},[_c('v-img',{attrs:{"contain":"","height":"80px","width":"100%","src":require('./../assets/images/SAIcopy.png')}})],1)],1),_c('v-flex',{attrs:{"xs2":"","sm1":"","md10":"","lg10":"","align-self-center":"","text-left":""}},[_c('div',[_c('router-link',{staticClass:"routerlink",attrs:{"to":(_vm.name = '/')}},[_c('span',{class:_vm.transAppBar ? 'maintitlehid' : 'maintitle'},[_vm._v(" Speleological Association of India ")])])],1)])],1)],1),_c('v-flex',{attrs:{"align-self-center":"","xs8":"","sm6":"","md6":"","lg6":"","text-right":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"sm12":"","md12":"","lg12":"","align-self-center":"","text-right":""}},[_c('v-layout',{attrs:{"wrap":"","justify-end":""}},_vm._l((_vm.menus),function(item,i){return _c('v-flex',{key:i,attrs:{"sm4":"","md2":"","lg2":"","xl2":"","align-self-center":"","text-center":""}},[(item.name == 'Research')?_c('span',{class:_vm.transAppBar ? 'hid' : 'hov',on:{"click":function($event){item.name == 'Research'
                          ? (_vm.menu = true)
                          : (_vm.menu = false)}}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-layout',_vm._g(_vm._b({class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"justify-center":"","color":"#86ab5ef3"}},'v-layout',attrs,false),on),[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                                  ? 'MDapplist'
                                  : 'LGapplist',staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.dropdown),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-card',{class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"width":"200px"}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                                      ? 'MDapplist'
                                      : 'LGapplist'},[_vm._v(_vm._s(item.title))])])],1)],1)}),1)],1)],1):(item.name == 'Get Involved')?_c('span',{class:_vm.transAppBar ? 'hid' : 'hov',on:{"click":function($event){item.name == 'Get Involved'
                          ? (_vm.menu = true)
                          : (_vm.menu = false)}}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-layout',_vm._g(_vm._b({class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"justify-center":"","color":"#86ab5ef3"}},'v-layout',attrs,false),on),[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                                  ? 'MDapplist'
                                  : 'LGapplist',staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.drop),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-card',{class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"width":"200px"}},[_c('router-link',{attrs:{"to":item.route}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                                        ? 'MDapplist'
                                        : 'LGapplist'},[_vm._v(_vm._s(item.title))])])],1)],1)],1)}),1)],1)],1):(item.name == 'Activities')?_c('span',{class:_vm.transAppBar ? 'hid' : 'hov',on:{"click":function($event){item.name == 'Activities'
                          ? (_vm.menu = true)
                          : (_vm.menu = false)}}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-layout',_vm._g(_vm._b({class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"justify-center":"","color":"#86ab5ef3"}},'v-layout',attrs,false),on),[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                                  ? 'MDapplist'
                                  : 'LGapplist',staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.activities),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-card',{class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"width":"200px"}},[_c('router-link',{attrs:{"to":item.route}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                                        ? 'MDapplist'
                                        : 'LGapplist'},[_vm._v(_vm._s(item.title))])])],1)],1)],1)}),1)],1)],1):_c('a',{class:_vm.transAppBar ? 'hid' : 'hov',attrs:{"href":item.route}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'md'
                            ? 'MDapplist'
                            : 'LGapplist'},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-list',_vm._l((_vm.subs),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }