<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="banner">
      <v-flex xs12 align-self-center style="margin-all: -1px;">
        <v-layout wrap justify-start>
          <v-flex
            xs12
            lg12
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'padding-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
            v-if="banner.image"
          >
            <v-img
              :contain="$vuetify.breakpoint.name == 'xs' ? false : true"
              :src="mediaURL + banner.image"
              class="align-center"
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
            <v-layout wrap py-0 px-md-2 px-lg-16>
              <v-flex xs12 align-self-center>
                <v-card
                  color="black"
                  :class="
                    $vuetify.breakpoint.name == 'xs' ? '' : 'bannerGrad rot'
                  "
                  :style="
                    $vuetify.breakpoint.name == 'xs'
                      ? 'margin-top: 0px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? 'margin-top: 0px'
                      : 'margin-top: -152px'
                  "
                >
                  <v-layout wrap py-8 px-14>
                    <v-flex xs12 sm11 md11 lg11>
                      <v-layout wrap justify-start>
                        <v-flex lg9 align-self-center text-justify>
                          <span
                            style="text-shadow: 4px 4px 8px #000000;"
                            :class="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'subxs'
                                : $vuetify.breakpoint.name == 'sm'
                                ? 'submd'
                                : 'subtitle'
                            "
                            :style="'color:' + banner.textColor"
                          >
                            {{ banner.text }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex sm1 md1 lg1 align-self-center text-right>
                      <v-layout
                        wrap
                        justify-end
                        class="d-flex align-end flex-column"
                      >
                        <v-flex
                          xs12
                          sm12
                          px-4
                          px-sm-0
                          lg12
                          py-4
                          align-self-end
                          class="hidden-xs-only mt-auto"
                          v-for="(item, i) in socialMediasList"
                          :key="i"
                        >
                          <a :href="item.link" target="_blank">
                            <v-img
                              contain
                              height="21px"
                              width="21px"
                              :src="item.image"
                            >
                              <template v-slot:placeholder>
                                <ImageLoader />
                              </template>
                            </v-img>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-3 align-self-start text-left>
                      <v-btn
                        class="positiontext text-capitalize"
                        dark
                        elevation="9"
                        color="white"
                        outlined
                        to="/Contact"
                        rounded
                        style="text-shadow: 4px 4px 6px #000000;"
                      >
                        <span>Contact Us &nbsp; </span>
                        <span><v-icon small>mdi-arrow-right</v-icon></span>
                      </v-btn>
                    </v-flex>

                    <!-- <v-flex xs12 py-4 align-self-start text-center>
                      <v-btn
                        dark
                        outlined
                        rounded
                        class="positiontext text-capitalize"
                        elevation="9"
                        color="white"
                        to="/Events"
                        style="text-shadow: 4px 4px 6px #000000;font-family: TitSemibold;color:white;font-size:20px"
                      >
                        <span>Upcoming Events</span>
                        <span><v-icon small>mdi-arrow-right</v-icon></span>
                        <span
                          style="font-family:TitSemibold"
                          class="hidden-xs-only"
                        >
                          <i>
                            Aquifers and caves: the groundwater perspective
                          </i></span
                        >
                      </v-btn>
                    </v-flex> -->
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["banner"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      socialMediasList: [
        {
          image: require("./../../assets/images/fb.svg"),
          link:
            "https://www.facebook.com/Speleological-Association-of-India-107212508367437/?ref=pages_you_manage",
        },
        {
          image: require("./../../assets/images/insta.png"),
          link: "https://instagram.com/speleoindia?utm_medium=copy_link",
        },
        {
          image: require("./../../assets/images/twitter.svg"),
          link: "https://twitter.com/SpeleoIndia?s=09",
        },
      ],
    };
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>
