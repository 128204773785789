<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pa-12 pa-xl-16 v-if="gallery">
      <v-flex xs12 align-self-center>
        <v-layout wrap v-if="gallery.length > 0">
          <v-flex xs12 align-self-center text-center>
            <span
              style="border-bottom:4px solid #BC4639;border-top:4px solid #BC4639"
              class="gallhead"
              >GALLERY</span
            >
          </v-flex>

          <v-flex xs12>
            <OwlCarousel
              :autoplay="true"
              :loop="true"
              :nav="false"
              :dots="false"
              :responsive="owlResponsive"
              @initialized="intial"
              @changed="changed"
              :events="['initialized', 'changed']"
            >
              <template slot="prev"
                ><span class="prev" hidden ref="prev">prev</span></template
              >
              <template slot="next"
                ><span class="next" hidden ref="next">next</span></template
              >
              <template v-for="(item, i) in gallery">
                <v-layout wrap :key="i">
                  <v-flex px-md-1 px-lg-1 px-sm-2 px-3>
                    <v-card flat color="rgba(0,0,0,0)" elevation="0">
                      <router-link to="/viewGallery">
                        <v-img
                          contain
                          height="320px"
                          :src="mediaURL + item.image"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#097392"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </router-link>
                    </v-card>
                  </v-flex>
                </v-layout>
              </template>
            </OwlCarousel>
          </v-flex>
          <v-flex sm8 class="hidden-xs-only">
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex
            xs12
            sm4
            align-self-center
            text-right
            class="cblack hidden-xs-only"
          >
          <router-link to="/viewGallery">
            <span class="poppinsregular" style="cursor:pointer">Show all</span
            >&nbsp; <span>{{ gallery.length }}</span
            >&nbsp;
          </router-link>
            <span>
              <v-icon
                @click="$refs.prev.click()"
                :disabled="pflag ? false : true"
                >mdi-arrow-left-drop-circle-outline</v-icon
              >
            </span>
            &nbsp;
            <span>
              <v-icon
                @click="$refs.next.click()"
                :disabled="nflag ? false : true"
                >mdi-arrow-right-drop-circle-outline</v-icon
              >
            </span>
            <br />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["gallery"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 3, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        2560: { items: 6, nav: false },
      },
      nflag: true,
      pflag: false,
    };
  },
  methods: {
    intial(event) {
      console.log("owl carousel initialized");
      console.log("number of slides:", event.item.count);
      console.log("active slide index:", event.item.index);
    },
    changed(event) {
      //
      if (event.item.index === 0) {
        this.pflag = false;
        this.nflag = true;
      } else {
        const currnetel = Math.floor(event.item.index + event.page.size);
        // last
        if (currnetel === event.item.count) {
          this.pflag = true;
          this.nflag = false;
        } else {
          this.pflag = true;
          this.nflag = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>
