<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout
      wrap
      justify-center
      pa-12
      pa-xl-16
      v-if="gallery"
      :style="
        $vuetify.breakpoint.name == 'xs'
          ? 'padding-top:0px !important;'
          : 'padding-top:100px !important;'
      "
    >
      <v-flex xs12 align-self-center>
        <v-layout wrap v-if="gallery.length > 0">
          <v-flex xs12 align-self-center text-center>
            <span
              style="
                border-bottom: 4px solid #bc4639;
                border-top: 4px solid #bc4639;
              "
              class="gallhead"
              >GALLERY</span
            >
          </v-flex>
          <v-flex xs12 py-8>
            <v-layout wrap>
              <v-flex xs12 lg12 pa-1>
                <v-card tile flat color="rgba(0,0,0,0)" elevation="0">
                  <viewer
                    :images="gallery"
                    :key="gallery.length"
                    style="display: flex"
                  >
                    <v-layout wrap>
                      <v-flex
                        align-self-center
                        xs6
                        px-2
                        px-lg-1
                        px-xl-1
                        sm6
                        md6
                        lg4
                        xl2
                        :key="i"
                        v-for="(item, i) in gallery"
                      >
                        <img
                          style="object-fit: contain;cursor:pointer"
                          width="100%"
                          :height="
                            $vuetify.breakpoint.name == 'sm'
                              ? '215px'
                              : $vuetify.breakpoint.name == 'xs'
                              ? '90px'
                              : $vuetify.breakpoint.name == 'lg'
                              ? 'auto'
                              : $vuetify.breakpoint.name == 'md'
                              ? '308px'
                              : '268px'
                          "
                          :src="mediaURL + item.image"
                        />
                      </v-flex>
                    </v-layout>
                  </viewer>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      gallery: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.gallery = response.data.data.gallery.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>
