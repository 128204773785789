var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#097392","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#097392","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.about)?_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[(_vm.about.image)?_c('v-flex',{attrs:{"xs12":"","align-self-start":""}},[_c('v-img',{staticClass:"align-end",attrs:{"cover":"","height":"450px","src":_vm.mediaURL + _vm.about.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"py-16":"","xs12":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"gall",style:('color:' + _vm.about.textColor)},[_vm._v("ABOUT US")])])],1)],1)],1):_vm._e()],1):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-4":"","pa-sm-12":"","pa-xl-16":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg10":"","align-self-center":"","text-justify":""}},[_c('span',{staticStyle:{"font-family":"TitLight","font-size":"25px","color":"black"},style:({
              'font-size':
                _vm.$vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '24px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '24px'
                  : '35px',
            })},[_vm._v(_vm._s(_vm.about.text)+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }