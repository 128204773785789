<template>
  <div class="conGrad">
    <v-layout wrap justify-start v-if="contact">
      <v-flex xs12 align-self-start  v-if="contact.image">
        <v-img
          cover
          class="align-end"
          height="450px"
          :src="mediaURL + contact.image"
        >
          <v-layout wrap justify-start>
            <v-flex xs12 pa-16 align-self-center text-left>
              <span
              :style="'color:'+ contact.textColor + ' !important;'"
                style=" font-size: 40px"
                class="con3"
                ><b> Contact Us</b></span
              >
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center px-sm-16 px-md-2 px-lg-2 v-if="contact">
      <v-flex xs12 lg12 xl8 align-self-center py-sm-4 px-sm-16 px-md-6 px-lg-16>
        <v-card
          :min-height="$vuetify.breakpoint.name == 'xl' ? '80vh' : '50vh'"
          flat
          color="transparent"
        >
          <v-layout wrap justify-center pt-md-12 pt-lg-0 pt-xl-16 pb-lg-12>
            <v-flex
              xs12
              md6
              lg7
              pl-4
              pl-sm-16
              pt-1
              pt-sm-1
              pt-md-0
              pt-xl-16
              align-self-center
            >
              <v-layout wrap v-if="address">
                <v-flex xs12 py-7 align-self-center text-left>
                  <span class="con3"><b> Address</b></span>
                </v-flex>
                <v-flex xs4 lg1 py-2 align-self-center text-left>
                  <v-img
                    width="25px"
                    height="30px"
                    contain
                    :src="require('./../../assets/images/locatxl.png')"
                  ></v-img>
                </v-flex>
                <v-flex pb-6 xs12 align-self-center text-left>
                  <span class="con2" v-html="address.address">
                  </span>
                  <!-- <span class="con2">
                    <span style="color: #097392"
                      ><b> Coimbatore, Tamilnadu </b></span
                    >
                    <br />

                    Address: 61, Aishwaryam Garden, KNG Pudur Pirivu, <br />
                    Coimbatore North 641025, Tamil Nadu, INDIA.
                  </span>
                  <br />
                  <span class="con2">
                    <span style="color: #097392"
                      ><b> Nagpur, Maharashtra </b></span
                    >
                    <br />

                    Address: 354/A Hanuman Nagar, Behind Corporation School,
                    Nagpur 440024, Maharashtra, INDIA.
                  </span> -->
                </v-flex>
                <v-flex xs4 lg1 py-2 align-self-center text-left>
                  <v-img
                    width="25px"
                    height="30px"
                    contain
                    :src="require('./../../assets/images/chat2x.png')"
                  ></v-img>
                </v-flex>
                <v-flex xs12 pb-6 align-self-center text-left>
                  <span class="con2" v-html="address.emails">
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              :style="
                $vuetify.breakpoint.name == 'md'
                  ? 'margin-top:-300px;'
                  : $vuetify.breakpoint.name == 'lg'
                  ? 'margin-top:-350px;'
                  : $vuetify.breakpoint.name == 'xl'
                  ? 'margin-top:-400px;'
                  : ''
              "
              xs12
              md6
              lg5
              align-self-center
              pt-xl-10
              pb-8
              pb-sm-10
              pb-md-0
            >
              <Cards />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Cards from "./contactCard";
import axios from 'axios'
export default {
  data() {
    return {
      contact: [],
      address:[]
    }
  },
  components: {
    Cards,
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/contact/page/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.contact = response.data.data.top
            this.address = response.data.data
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>

<style  scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #fcfbfb 0%, #ffffff 100%, transparent 100%);
}
</style>