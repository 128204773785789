<template>
  <div>
    <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeOut">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout
      wrap
      justify-center
      px-3
      px-sm-10
      px-md-8
      px-lg-10
      pb-md-16
      py-lg-16
    >
      <v-flex xs12 align-self-center>
        <v-card :rounded="true" v-if="!flag">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout wrap py-8>
              <v-flex xs12 px-4 px-sm-8 py-2 pb-10 align-self-center text-left>
                <span class="con3"><b> Reach out!</b></span>
              </v-flex>
              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <v-text-field
                      class="tes"
                      label="Name"
                      v-model="contact.name"
                      :rules="nameRules"
                      color="#097392"
                      required
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex> -->

              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <!-- <v-flex xs12 text-left>
                  <span class="con4">Email</span>
                </v-flex> -->
                  <v-flex xs12 text-left>
                    <v-text-field
                      required
                      :rules="emailRules"
                      class="tes"
                      label="Email"
                      v-model="contact.email"
                      color="#097392"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <v-select
                      class="tes"
                      label="How did you get to know about us?"
                      required
                      :rules="reqRules"
                      v-model="contact.know"
                      :items="knownList"
                      item-text="name"
                      item-value="_id"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex> -->
              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <v-text-field
                      class="tes"
                      label="Country"
                      v-model="contact.country"
                      :rules="reqRules"
                      color="#097392"
                      required
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <v-text-field
                      class="tes"
                      label="State"
                      v-model="contact.state"
                      :rules="reqRules"
                      color="#097392"
                      required
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <v-text-field
                      class="tes"
                      label="Subject of your message"
                      v-model="contact.subject"
                      color="#097392"
                      required
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 px-4 px-sm-8 pb-5>
                <v-layout wrap>
                  <!-- <v-flex xs12 text-left>
                  <span class="con4">Message</span>
                </v-flex> -->
                  <v-flex xs12 text-left>
                    <v-textarea
                      class="tes"
                      v-model="contact.message"
                      label="Your message"
                      :rules="msgRules"
                      dense
                      color="#097392"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-spacer></v-spacer>
              </v-flex>

              <v-flex px-8 align-self-end text-right>
                <v-btn
                  @click="validate"
                  :disabled="!valid"
                  dark
                  rounded
                  :ripple="false"
                >
                  <span>Send</span>
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>

        <!-- ELSE  -->

        <v-card class="tanGrad rot" :rounded="true" dark v-if="flag">
          <v-layout wrap pa-8>
            <v-flex xs12 pb-1 align-self-end text-right>
              <v-btn plain @click="(flag = false), clearOff()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
            <v-flex
              xs12
              xl6
              pt-16
              pb-0
              style="padding-top: 180px !important"
              align-self-center
              text-left
            >
              <span class="con5">Thank You.</span>
            </v-flex>
          </v-layout>

          <v-layout wrap py-2>
            <v-flex xs12 align-self-center>
              <v-divider style="background-color: gold"></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap pa-8>
            <v-flex xs12 lg8 xl6 py-0 align-self-center text-left>
              <span class="con6"> We’ll be in touch. Shortly! </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
// import Cards from "@/components/Home2/Cards";
export default {
  data() {
    return {
      appLoading: false,
      flag: false,
      valid: true,
      contact: {
        name: "",
        message: "",
        email: "",
        country: null,
        state: null,
        know: null,
        subject: null,
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      msgRules: [(v) => !!v || "Message is required"],
      reqRules: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length == 10 || "Invalid Phone Number",
      ],
      knownList: [
        "Social media",
        "Search engines",
        "Newspapers and/or Magazines",
        "From a friend",
      ],
      showSnackBar: false,
      timeOut: 5000,
      msg: null,
    };
  },
  components: {
    // Cards,
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.addContact();
    },
    addContact() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/contact/add",
        data: this.contact,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            Object.keys(this.contact).forEach(
              (key) => (this.contact[key] = null)
            );
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },
    clearOff() {
      this.contact.name = null;
      this.contact.email = null;
      this.contact.phone = null;
      this.contact.message = null;
    },
  },
};
</script>

<style  scoped>
.cardbg {
  /* background: transparent
    linear-gradient(80deg, hsla(209, 98%, 49%, 0.541) 100%, #000000 100%, transparent 100%); */

  background: transparent
    linear-gradient(
      120deg,
      hsla(0, 69%, 87%, 0.137) 10%,
      #ffffff6c 100%,
      transparent 100%
    );
}
.conGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
.tanGrad {
  background: transparent
    linear-gradient(100deg, #1d1d1d 0%, #262626 100%, transparent 100%);
}
/* .tanGrad:hover {
  
  transition:  transform 1s;
  transform: rotateY(360deg);
} */
.graddy {
  background: linear-gradient(150deg, #39ef74, #4600f1 100%);
}

.rot {
  animation-duration: 3s;
  animation-name: slidein;
  /* animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; */
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
/* @keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
/* .rot:hover {
  transform: rotate(360deg);
} */
</style>