<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#D55534"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#D55534" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="top" >
      <v-flex xs12 align-self-start v-if="top.image">
        <v-img
          cover
          height="450px"
          :src="mediaURL + top.image"
          class="align-end"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap v-if="top">
            <v-flex xs12 align-self-center text-center py-16 pt-10>
              <span
                :style="'color:' + top.textColor + '!important'"
                style="border-bottom: 0px solid #097392"
                class="gall"
                >OUR TEAM</span
              >
            </v-flex>
          </v-layout></v-img
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pa-4 pa-sm-12 pa-xl-16 v-if="team">
      <v-flex xs12 align-self-center v-if="team.length > 0">
        <v-layout
          fill-height
          py-5
          justify-md-center
          justify-sm-start
          wrap
          v-for="(item, i) in team"
          :key="i"
        >
          <template v-if="i % 2 == 0">
            <v-flex
              class="hidden-md-and-up"
              xs12
              sm4
              md3
              lg3
              align-self-start
              pt-xl-1
              pt-2
            >
              <v-img :src="mediaURL + item.image"></v-img>
            </v-flex>
            <v-flex xs12 sm12 md9 lg9 px-sm-2 align-self-start text-justify>
              <span
                :style="
                  $vuetify.breakpoint.name == 'xl' ? 'font-size:37px' : ''
                "
                class="nametext"
                >{{ item.name }}</span
              ><br />
              <span
                :style="
                  $vuetify.breakpoint.name == 'xl' ? 'font-size:32px' : ''
                "
                class="positiontext"
                ><em>{{ item.designation }}</em></span
              ><br /><br />
              <span
                v-html="item.description"
                class="contenttext"
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xs'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '18px'
                      : '35px',
                }"
              >
              </span>
            </v-flex>
            <v-flex
              class="hidden-sm-and-down"
              xs12
              sm4
              md3
              lg3
              align-self-start
              pt-xl-1
              pt-2
              pl-3
            >
              <v-img :src="mediaURL + item.image"></v-img>
            </v-flex>
          </template>
          <template v-if="i % 2 == 1">
            <v-flex
              xs12
              sm4
              md3
              lg3
              align-self-start
              pt-xl-2
              pt-2
              pl-md-0
              pl-0
              pr-3
            >
              <v-img contain :src="mediaURL + item.image"></v-img>
            </v-flex>
            <v-flex xs12 sm12 md9 lg9 px-sm-2 align-self-start text-justify>
              <span
                :style="
                  $vuetify.breakpoint.name == 'xl' ? 'font-size:37px' : ''
                "
                class="nametext"
                >{{ item.name }}</span
              ><br />
              <span
                :style="
                  $vuetify.breakpoint.name == 'xl' ? 'font-size:32px' : ''
                "
                class="positiontext"
                ><em>{{ item.designation }}</em></span
              ><br /><br />
              <span
                class="contenttext"
                v-html="item.description"
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xs'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '18px'
                      : '35px',
                }"
              >
              </span>
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      team: [],
      top: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/team/page/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.team = response.data.data.team.data;
            this.top = response.data.data.top;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bgm {
  background-attachment: fixed;
  object-fit: contain;
}
.colors {
  color: white;
}
</style>