<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start v-if="topText">
      <v-flex xs12 align-self-start v-if="topText.image">
        <v-img
          cover
          height="450px"
          class="align-end"
          :src="mediaURL + topText.image"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-space-between>
          <v-flex xs12 text-left text-uppercase>
            <span class="mem">Volunteer</span>
          </v-flex>
          <v-flex xs12 pt-2>
            <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout justify-center wrap>
          <v-flex
            xs12
            sm12
            md12
            lg12
            align-self-center
            text-center
            py-6
            py-xl-10
            v-if="topText"
          >
            <span
              style="font-family: TitLight; font-size: 25px; color: black"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '14px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '20px'
                    : '35px',
              }"
              v-html="topText.text"
            >
            </span>
          </v-flex>
          <v-flex xs12 lg7 align-self-center>
            <v-layout
              wrap
              justify-center
              px-3
              px-sm-10
              px-md-8
              px-lg-10
              pb-md-16
              pb-lg-10
              pt-lg-2
            >
              <v-flex xs12 align-self-center>
                <v-card :rounded="true">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-layout wrap py-8>
                      <v-flex
                        xs12
                        px-4
                        px-sm-8
                        py-2
                        pb-10
                        align-self-center
                        text-left
                      >
                        <span class="con3"><b> Volunteer Form</b></span>
                      </v-flex>
                      <v-flex xs12 px-4 px-sm-8 pb-5>
                        <v-layout wrap>
                          <v-flex xs12 text-left py-2>
                            <v-select
                              class="tes"
                              label="Salutation"
                              required
                              :rules="reqRules"
                              v-model="contact.salutation"
                              :items="contact.salutationGroup"
                              item-text="name"
                              item-value="_id"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="First Name"
                              v-model="contact.name.firstName"
                              :rules="nameRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Middle Name"
                              v-model="contact.name.middleName"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Last Name"
                              v-model="contact.name.surname"
                              :rules="nameRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <span
                              style="
                                color: #363434 !important;
                                font-family: TitSemibold;
                              "
                              >Gender</span
                            >
                            <br />
                            <v-radio-group
                              :rules="reqRules"
                              dense
                              v-model="contact.gender"
                            >
                              <v-radio
                                v-for="n in contact.genderGroup"
                                :key="n"
                                :label="n"
                                :value="n"
                                class="tes"
                              ></v-radio>
                            </v-radio-group>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Age(Years)"
                              type="Number"
                              v-model="contact.age"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>

                          <!-- <v-flex xs12 text-left>
                            <v-textarea
                              class="tes"
                              v-model="contact.message"
                              label="Your message"
                              :rules="msgRules"
                              dense
                              color="#097392"
                            ></v-textarea>
                          </v-flex> -->
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Address/Building Number"
                              v-model="contact.address.buildingNo"
                              :rules="reqRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Street"
                              v-model="contact.address.street"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Landmark"
                              v-model="contact.address.landmark"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="City"
                              v-model="contact.address.city"
                              :rules="reqRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="District"
                              v-model="contact.address.district"
                              :rules="reqRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="State"
                              v-model="contact.address.state"
                              :rules="reqRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Country"
                              v-model="contact.address.country"
                              :rules="reqRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Pincode"
                              type="Number"
                              v-model="contact.address.pincode"
                              :rules="pinRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Contact Number"
                              type="Number"
                              v-model="contact.phone"
                              :rules="phoneRules"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              required
                              :rules="emailRules"
                              class="tes"
                              label="Email"
                              v-model="contact.email"
                              color="#097392"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <v-text-field
                              class="tes"
                              label="Work Interest"
                              v-model="contact.workInterest"
                              color="#097392"
                              required
                              :rules="reqRules"
                              dense
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 text-left py-2>
                            <v-select
                              class="tes"
                              label="Where did you hear about us?"
                              required
                              :rules="reqRules"
                              v-model="contact.hearAboutUs"
                              :items="knownList"
                              item-text="name"
                              item-value="_id"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex
                            xs12
                            text-left
                            py-2
                            v-if="
                              contact.hearAboutUs == 'Others, Please specify'
                            "
                          >
                            <v-text-field
                              class="tes"
                              label="Specify where did you hear about us?"
                              v-model="contact.hearAboutUsSpecify"
                              color="#097392"
                              required
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 text-left py-2>
                            <span
                              style="
                                color: #363434 !important;
                                font-family: TitSemibold;
                              "
                              >Upload your CV</span
                            >
                            &nbsp;
                            <input
                              v-show="false"
                              accept="image/*,.pdf"
                              id="file1"
                              ref="files"
                              type="file"
                              @change="browseImage"
                            />
                            <v-icon @click="$refs.files.click()" color="black"
                              >mdi-upload</v-icon
                            >
                            <br />
                            <br />
                            <span
                              style="
                                padding-bottom: 6px;
                                border-bottom: 2px solid grey;
                              "
                              v-if="cvFile"
                              >{{ cvFile.name }}
                              <v-icon color="red" small @click="cvFile = null"
                                >mdi-close</v-icon
                              ></span
                            >

                            <!-- <v-file-input
                              accept="image/*,.pdf"
                              id="file1"
                              ref="files"
                              type="file"
                              @change="browseImage"
                              prepend-icon="mdi-file-document"
                              label="Upload your CV"
                            ></v-file-input> -->
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <!-- <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex> -->

                      <v-flex xs12 align-self-center>
                        <v-spacer></v-spacer>
                      </v-flex>

                      <v-flex px-8 align-self-end text-right>
                        <v-btn
                          @click="validate"
                          :disabled="!valid"
                          dark
                          rounded
                          :ripple="false"
                        >
                          <span>Submit</span>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card>

                <!-- ELSE  -->

                <!-- <v-card class="tanGrad rot" :rounded="true" dark v-if="flag">
          <v-layout wrap pa-8>
            <v-flex xs12 pb-1 align-self-end text-right>
              <v-btn plain @click="(flag = false), clearOff()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
            <v-flex
              xs12
              xl6
              pt-16
              pb-0
              style="padding-top: 180px !important"
              align-self-center
              text-left
            >
              <span class="con5">Thank You.</span>
            </v-flex>
          </v-layout>

          <v-layout wrap py-2>
            <v-flex xs12 align-self-center>
              <v-divider style="background-color: gold"></v-divider>
            </v-flex>
          </v-layout>
          <v-layout wrap pa-8>
            <v-flex xs12 lg8 xl6 py-0 align-self-center text-left>
              <span class="con6"> We’ll be in touch. Shortly! </span>
            </v-flex>
          </v-layout>
        </v-card> -->
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
            lg12
            py-4
            py-sm-0
            pt-sm-8
            pt-md-0
            align-self-center
            text-center
            v-if="volunteer"
          >
            <span
              style="font-family: TitLight; font-size: 25px; color: black"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '14px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '20px'
                    : '35px',
              }"
              v-html="volunteer.bottomText"
            >
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      flag: false,
      valid: true,
      contact: {
        salutation: "",
        name: {
          firstName: "",
          surname: "",
          middleName: "",
        },
        address: {
          buildingNo: "",
          street: "",
          landmark: "",
          city: "",
          district: "",
          state: "",
          country: "",
          pincode: "",
        },
        phone: "",
        message: "",
        email: "",
        hearAboutUs: "",
        hearAboutUsSpecify: "",
        workInterest: "",
        gender: "",
        age: "",
        genderGroup: ["Male", "Female", "Other", "Prefer not to answer"],
        salutationGroup: ["Mr", "Mrs", "Ms", "Dr"],
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      msgRules: [(v) => !!v || "Message is required"],
      reqRules: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length == 10 || "Invalid Phone Number",
      ],
      pinRules: [
        (v) => !!v || "Pincode is required",
        (v) => v.length == 6 || "Invalid Pincode",
      ],
      ageRules: [
        (v) => !!v || "Age is required",
        (v) => v.length <= 3 || "Invalid Age",
        (v) => v > 0 || "Invalid Age",
      ],
      rules: [
        (value) =>
          !value ||
          value.size < 3000000 ||
          "Document size should be less than 3 MB!",
      ],
      volunteer: [],
      topText: [],
      knownList: [
        "Facebook",
        "Instagram",
        "Twitter",
        "Newspaper",
        "SAI website",
        "Friend",
        "Others, Please specify",
      ],
      formDataCv: new FormData(),
      cvFile: null,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/volunteer/page/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.volunteer = response.data.data;
            this.topText = this.volunteer.top;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },
    browseImage(event) {
      var got = event.target.files[0];

      if (got.type == "application/pdf") {
        this.cvFile = got;
      } else {
        var img;
        img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        var ty = event.target.files[0];
        console.log(ty);
        if (ty.size > 3145728) {
          this.msg = "File size Should be less than 3MB";
          this.showSnackBar = true;
          return;
        } else {
          this.cvFile = got;

          // img.onload = () => {
          //   this.cvFile = URL.createObjectURL(event.target.files[0]);
          // };
          // console.log(img);
        }
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.addContact();
        // else {
        //   this.msg = "Please upload CV";
        //   this.showSnackBar = true;
        // }
      }
    },
    addContact() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/volunteer/add",
        data: this.contact,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.id) {
              if (this.cvFile) {
                this.uploadCV(response.data.id);
              }
            }
            this.clearOff();
            // Object.keys(this.contact).forEach(
            //   (key) => (this.contact[key] = '')
            // );
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },
    uploadCV(item) {
      this.appLoading = true;
      this.formDataCv.append("id", item);
      this.formDataCv.append("cv", this.cvFile);
      axios({
        method: "POST",
        url: "/volunteer/cv",
        data: this.formDataCv,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showSnackBar = true;
            this.formDataCv = new FormData();
            this.cvFile = null;
            this.$refs.form.resetValidation();
            // this.$router.go();
          } else {
            this.formDataCv = new FormData();
            this.msg = "Can't Upload CV.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Uncaught!Can't Upload CV.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    clearOff() {
      this.contact.name.firstName = "";
      this.contact.name.middleName = "";
      this.contact.name.surname = "";
      this.contact.address.buildingNo = "";
      this.contact.address.street = "";
      this.contact.address.city = "";
      this.contact.address.landmark = "";
      this.contact.address.district = "";
      this.contact.address.state = "";
      this.contact.address.country = "";
      this.contact.address.pincode = "";
      this.contact.email = "";
      this.contact.phone = "";
      this.contact.salutation = "";
      this.contact.gender = "";
      this.contact.age = "";
      this.contact.workInterest = "";
      this.contact.hearAboutUs = "";
      this.contact.hearAboutUsSpecify = "";
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>