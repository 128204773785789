<template>
  <div>
    <v-divider></v-divider>
    <v-footer
      color="#097392"
      :min-height="$vuetify.breakpoint.name == 'xs' ? 'auto' : '140px'"
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex align-self-center xs12 px-md-4 px-lg-6>
              <v-layout
                wrap
                justify-center
                :text-left="$vuetify.breakpoint.name == 'xs' ? true : false"
              >
                <v-flex xs12 sm2 md2 lg2 align-self-center>
                  <v-layout
                    wrap
                    justify-start
                    :justify-center="$vuetify.breakpoint.name == 'xs'"
                  >
                    <v-flex xs5 sm6 md5 lg5 align-self-center text-left>
                      <router-link class="routerlink" :to="(name = '/')">
                        <v-img
                          contain
                          height="85px"
                          :src="require('./../assets/images/SAIcopy.png')"
                        ></v-img>
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm10 md8 lg8 align-self-center>
                  <v-layout wrap justify-center>
                    <v-flex sm12 md12 lg12 py-5 align-self-center>
                      <v-layout wrap>
                        <v-flex
                          v-for="(item, i) in menus"
                          :key="i"
                          py-2
                          py-sm-0
                          :xs4="$vuetify.breakpoint.name == 'xs' ? true : false"
                          align-self-center
                          text-left
                        >
                          <!-- <router-link class="routerlink" :to="item.route">
                            <span
                              style="
                                font-family: TitSemibold;
                                font-size: 16px;
                                color: #d1d1d1;
                              "
                              >{{ item.name }}</span
                            >
                          </router-link> -->
                          <a plain v-if="item.name == 'About Us'">
                            <span
                              style="
                                font-family: TitSemibold;
                                font-size: 16px;
                                color: #d1d1d1;
                              "
                              @click="scrollToElement('aboutto')"
                            >
                              {{ item.name }}
                            </span>
                          </a>
                          <a v-else :href="item.route">
                            <span
                              style="
                                font-family: TitSemibold;
                                font-size: 16px;
                                color: #d1d1d1;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      md3
                      lg3
                      text-sm-left
                      align-self-center
                      text-center
                    >
                      <span
                        style="
                          color: #d1d1d1;
                          font-family: TitSemibold;
                          font-size: 14px;
                        "
                        >LEOPARD TECH LABS PVT. LTD</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs5
                  py-sm-4
                  py-md-2
                  sm3
                  md2
                  lg2
                  py-2
                  align-self-end
                  text-right
                >
                  <v-layout px-md-6 px-lg-12 px-xl-16 wrap justify-center>
                    <v-flex v-for="(item, i) in socialMediasList" :key="i">
                      <a :href="item.link" target="_blank">
                        <v-img
                          contain
                          height="21px"
                          width="21px"
                          :src="item.image"
                        >
                          <template v-slot:placeholder>
                            <ImageLoader />
                          </template>
                        </v-img>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userNavItems: [
        { name: "About Us", route: "/About" },
        { name: "Gallery", route: "/viewGallery" },
        { name: "Team", route: "/team" },
        { name: "Contact Us", route: "/Contact" },
      ],
      socialMediasList: [
        {
          image: require("./../assets/images/fb.svg"),
          link:"https://www.facebook.com/Speleological-Association-of-India-107212508367437/?ref=pages_you_manage"
        },
        {
          image: require("./../assets/images/insta.png"),
          link:"https://instagram.com/speleoindia?utm_medium=copy_link"
        },
        {
          image: require("./../assets/images/twitter.svg"),
          link: "https://twitter.com/SpeleoIndia?s=09"

        },
        {
          image: require("./../assets/images/yt.png"),
          link: "https://www.youtube.com/channel/UCAvmeO3cxRPsScgJXMdDoGw"

        },
        {
          image: require("./../assets/images/blog.png"),
          link: "https://speleoindia.blogspot.com/"

        },
      ],
    };
  },
  computed: {
    menus() {
      return this.userNavItems;
    },
  },
  methods: {
    scrollToElement(id) {
      if (this.$route.path !== "/") {
        this.$router.push("/").then(() => {
          document.getElementById(id).scrollIntoView({
            behavior: "smooth",
          });
        });
      } else {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
};
</script>