<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#097392"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left align-self-center>{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-start v-if="top">
        <v-flex xs12 align-self-start v-if="top.image">
          <v-img
            cover
            height="450px"
            class="align-end"
            :src="mediaURL + top.image"
          >
            <template v-slot:placeholder>
              <ImageLoader />
            </template>
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start pa-4 pa-sm-12 pa-xl-16>
        <v-flex xs12 align-self-center>
          <v-layout wrap justify-space-between>
            <v-flex xs12 text-left text-uppercase>
              <span class="mem">World’s largest cavefish- India’s National Cave Animal of the Year 2025</span>
            </v-flex>
            <v-flex xs12 pt-2>
              <v-progress-linear value="100" color="#bc4639"></v-progress-linear>
            </v-flex>
          </v-layout>
          <v-layout justify-start wrap pt-4>
            <v-flex 
            xs12 sm12 md12 
            class="align-self-start text-justify text-left pr-md-4" 
            style="padding: 16px; line-height: 1.6;"
          >
            <span
            style="font-family: TitLight; font-size: 25px; color: black"
            :style="{
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '20px'
                  : $vuetify.breakpoint.name == 'md'
                  ? '20px'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '20px'
                  : '35px',
            }"
            >
            <v-flex  v-for="(item, i) in imgs" :key="i">
                <!-- <v-img :src="item.file"></v-img> -->
                <img
                  style="object-fit: contain;cursor:pointer"
                  width="100%"
                  :src="item.file"
                />
              </v-flex>
              <p style="text-align: right;font-size: 14px;"><i>Picture Credit : © Dhritiman Mukherjee</i></p>
            <h4 style="font-size: 18px; margin-top: 16px;">Introduction</h4>
          
              <p>
                The Union Internationale de Spéléologie (UIS) is organising a series of high-profile campaigns to draw attention to the importance of protecting karst landscapes and their diverse karst phenomena. One of these campaigns is the selection of an international ‘Cave Animal of the Year’. By choosing the world’s largest cavefish Neolissochilus pnar, as India’s cave animal of the year 2025, the Speleological Association of India wants to draw the attention of the concerned local, national, and international stakeholders and policy/decision makers to the species’ specialized habitat and vulnerability to environmental disturbances, like deforestation and tourism. And emphasise the required conservation of the species and cave ecosystems. 
              </p>
          
              <h4 style="font-size: 18px; margin-top: 16px;">Description</h4>
              <p>
                The fish species was first encountered in the caves of Meghalaya, North-eastern India, in 2019 and officially described as Neolissochilus pnar. Measuring up to 1.5 feet (approximately 40 cm) in length, this species is the largest known cave-dwelling fish, significantly surpassing the typical size of most cavefish, which are around 10 cm long. Initially suspected to be a troglomorphic form of the Golden Mahaseer (Tor putitora), detailed morphometric, meristic, and molecular analyses confirmed its classification as a member of the genus Neolissochilus, a sister taxon of Tor. Adapted to the pitch-dark conditions of underground limestone cave systems, N. pnar is blind, lacks pigmentation, and relies on non-visual senses for navigation and survival. 
              </p>
          
              <h4 style="font-size: 18px; margin-top: 16px;">Distribution</h4>
             <p>Neolissochilus pnar is exclusively found in the cave systems of Meghalaya, Northeastern India, particularly within subterranean river networks. These caves are part of extensive limestone formations, creating a vast underground network of rivers, lakes, and ponds. The water within these systems is clear but devoid of sunlight, and the ecosystem is characterized by minimal nutrient availability. The fish sustains itself by consuming small invertebrates and microorganisms. The caves maintain stable temperature and humidity levels due to their insulated nature, providing a consistent yet resource-scarce environment that has shaped the unique adaptations of this species.</p>
          
             <h4 style="font-size: 18px; margin-top: 16px;">Readings</h4>
              <p>
                Dahanukar, N., Sundar, R. L., Rangad, D., Proudlove, G., & Raghavan, R. (2023). The world’s largest cave fish from Meghalaya, Northeast India, is a new species, Neolissochilus pnar (Cyprinidae, Torinae). Vertebrate Zoology, 73, 141-152.
              </p>
            </span>
           
          </v-flex>
          
            <!-- <v-flex xs12 md5 py-2 py-md-0 align-self-start v-if="evimg">
              <viewer :images="imgs" :key="imgs.length" style="display: flex">
                <v-layout wrap>
                  <v-flex xs12 sm6 md5 pa-2 v-for="(item, i) in imgs" :key="i">
                   
                    <img
                      style="object-fit: contain;cursor:pointer"
                      width="100%"
                      :src="item.file"
                    />
                  </v-flex>
                </v-layout>
              </viewer>
            </v-flex> -->
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        membership: [],
        top: [],
        evimg: require("./../../assets/images/blindfish.jpg"),
        imgs: [
          {
            file: require("./../../assets/images/blindfish.jpg"),
          },
          
        ],
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/membership/page/get",
        })
          .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              this.membership = response.data.data;
              this.top = this.membership.top;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
  
            console.log(err);
          });
      },
    },
  };
  </script>
  <style scoped>
  .grad {
    background-image: linear-gradient(180deg, red, yellow);
  }
  </style>
  